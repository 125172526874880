const { default: calculatePizzaPrice } = require('./calculatePizzaPrice');
const { default: formatMoney } = require('./formatMoney');

const calculateOrderTotal = (order, pizzas) =>
  order.reduce(
    (subTotal, currentOrder) =>
      subTotal +
      calculatePizzaPrice(
        pizzas.find((p) => p.id === currentOrder.id).price,
        currentOrder.size
      ),
    0
  );
export default calculateOrderTotal;
